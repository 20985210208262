<template>
  <div class="partnerOrder">
    <!-- 确认订单 -->
    <div class="order_affirm">
      <div class="affirm_title">确认订单</div>
      <div class="affirm_content" v-for="(item, index) in detail.orderParticularsList" :key="index">
        <div class="affirm_left">
          <el-image class="affirm_img" :src="item.commodityImg"></el-image>
          <div class="affirm_name">{{ item.commodityName }}</div>
        </div>
        <div class="affirm_right">￥{{ item.commodityPrice }}</div>
      </div>
    </div>
    <!-- 支付信息 -->
    <div class="order_pay">
      <div class="pay_title">支付信息</div>
      <div class="pay">
        <div class="pay1" v-if="detail.orderPrice || detail.orderPrice === 0">
          订单金额：￥{{ detail.orderPrice.toFixed(2) }}
        </div>
        <div class="pay1" v-if="detail.discountPrice || detail.discountPrice === 0">
          优惠金额：￥{{ detail.discountPrice.toFixed(2) }}
        </div>
        <div class="pay1" v-if="detail.paidPrice || detail.paidPrice === 0">
          应付金额：￥{{ detail.paidPrice.toFixed(2) }}
        </div>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="order_way">
      <div class="way_title">支付方式</div>
      <div class="way">
        <div :class="form.type == 1 ? 'activepay' : 'pay-btn'" @click="zhifu(1)">
          <img class="logo-img" src="@/assets/img/Home/alipay.png" />
          支付宝支付
        </div>
        <div :class="form.type == 2 ? 'activepay' : 'pay-btn'" @click="zhifu(2)">
          <img class="logo-img" src="@/assets/img/Home/weixinpay.png" />
          微信支付
        </div>
      </div>
    </div>
    <!-- 提交订单 -->
    <div class="order">
      <div class="order_title">
        需付金额：<span class="price" v-if="detail.paidPrice || detail.paidPrice === 0">{{ detail.paidPrice.toFixed(2)
          }}</span>
      </div>
      <div class="order_btn" @click="lijizhifu">立即购买</div>
    </div>
    <wx-payment v-if="ifwXpay" :url="payDetail.code_url" :out-trade-no="payDetail.out_trade_no"
      :price="payDetail.total_fee" @invalid="wxPay()" @close="ifwXpay === false" />
  </div>
</template>
<script>
import { getPcAgreement } from "@/api/login";
import { testPay, pcwxtestPay } from "@/api/home";
import { getUidOrder } from "@/api/user";
import { CouponsClient } from "@/api/mine/couponsClient";
import wxPayment from "./wxPayment.vue";
import { getInfo, getDomain } from "@/api/cookies";
const couponsClient = new CouponsClient();
export default {
  components: { wxPayment },
  data() {
    return {
      copyright: {},
      /* { name: "同行转型", key: 1 },   { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */ form: {
        type: 1, // 支付类型
        discountPrice: 0, // 优惠金额
        isInvoice: "1", // 是否需要发拼
        couponId: null, // 选择优惠券
      },
      checkboxs: "",
      detail: {}, // 订单信息
      userInfo: "",
      ifwXpay: false, // 微信支付二维码
      payDetail: {}, // 微信支付详情
      couponList: [],
      /* 支付弹窗 */
      pay: false,
      ifSucces: false,
      /* 协议 */
      agreement: null,
      payment: 1,
    };
  },
  async created() {
    this.copyright = getDomain();
    this.userInfo = getInfo();
    await this.loadgetUidOrder();
    if (this.detail.commodityType == 1) {
      this.getPcAgreement();
    }
    this.useDiscountCouponCode();
  },
  methods: {
    // 新增订单接口
    async loadgetUidOrder() {
      await getUidOrder(this.$route.params.orderNumber).then((res) => {
        this.detail = res.msg;
      });
    },
    // 查看可使用的优惠券
    useDiscountCouponCode() {
      if (!this.userInfo || !this.detail) {
        return;
      }
      const type = this.detail.orderParticularsList.map((item) => {
        return item.commodityType;
      });
      const ids = this.detail.orderParticularsList.map((item) => {
        return item.commodityId;
      });
      couponsClient
        .useDiscountCouponCode(
          this.detail.orderPrice,
          type.join(","),
          this.userInfo.id,
          ids.join(",")
        )
        .then((res) => {
          if (res.code == 0) {
            this.couponList = res.data;
          }
        });
    },
    // 选择优惠券
    use(item) {
      if (this.form.couponId == item.couponCodeId) {
        this.form.couponType = null;
        this.form.fullDiscountRate = null;
        this.form.discountPrice = 0;
        this.form.couponId = null;
      } else {
        this.form.couponType = item.couponType;
        this.form.fullDiscountRate = item.fullDiscountRate / 100;
        this.form.discountPrice = item.couponValue;
        this.form.couponId = item.couponCodeId;
      }
    },
    /* 查询是否有协议 */
    getPcAgreement() {
      getPcAgreement(this.detail.commodityId).then((res) => {
        if (res.code == 0) {
        }
      });
    },
    // 支付方式选择
    zhifu(index) {
      this.form.type = index;
    },
    // 立即支付
    lijizhifu() {
      if (this.res && this.checkboxs !== true) {
        this.$message.error("请先阅读并同意协议内容！");
      } else if (this.form.isInvoice !== "0" && this.form.isInvoice !== "1") {
        this.$message.error("请选择是否需要发票！");
      } else if (!this.form.type) {
        this.$message.error("请选择支付方式！");
      } else {
        if (this.form.type == 1) {
          // 支付宝支付接口
          testPay(
            this.detail.orderNumber,
            this.detail.paidPrice - this.form.discountPrice > 0
              ? this.detail.paidPrice - this.form.discountPrice
              : 0,
            this.detail.commodityName,
            this.form.isInvoice,
            this.form.couponId
          ).then((res) => {
            if (res.msg == 200) {
              this.$router.push({
                path: "/payment",
                query: { data: res.code },
              });
            } else if (res.msg == 205) {
              this.pay = true;
              this.ifSucces = true;
            } else if (res.msg == 500) {
              this.pay = true;
              this.ifSucces = false;
            }
          });
        } else {
          // 微信支付接口
          this.wxPay();
        }
      }
    },
    /* 我知道了 */
    /* 关闭组件 */
    closeCom() {
      if (this.ifSucces) {
        if (this.copyright.entrantsType == 2) {
          this.$router.push({ path: "/all/mycurriculum" });
        } else if (this.copyright.entrantsType == 1) {
          this.$router.push({ path: "/allsixth/mycurriculumsixth" });
        } else if (this.copyright.entrantsType == 3) {
          this.$router.push({ path: "/seventhPage/mycurriculum" });
        }
        /*   */
      } else {
        this.pay = false;
      }
    },
    wxPay() {
      pcwxtestPay(
        this.detail.orderNumber,
        this.detail.commodityName,
        this.form.isInvoice,
        this.form.couponId
      ).then((res) => {
        if (res.msg == 200) {
          this.payDetail = res;
          this.ifwXpay = true;
          this.$forceUpdate();
        } else if (res.msg == 205) {
          this.pay = true;
          this.ifSucces = true;
        } else if (res.msg == 500) {
          this.pay = true;
          this.ifSucces = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.partnerOrder {
  width: 100%;

  .order_affirm {
    width: 1200px;
    margin: 20px auto 0;

    .affirm_title {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
      margin-left: 5px;
    }

    .affirm_content {
      width: 1200px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      margin-top: 10px;
      padding: 16px 27px 16px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .affirm_left {
        display: flex;
        align-items: flex-start;

        .affirm_img {
          width: 203px;
          height: 114px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
        }

        .affirm_name {
          font-size: 18px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #333333;
          margin-left: 14px;
        }
      }

      .affirm_right {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  .order_pay {
    width: 1200px;
    margin: 20px auto 0;

    .pay_title {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
      margin-left: 5px;
    }

    .pay {
      width: 1200px;
      height: 55px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 18px;

      .pay1 {
        font-size: 14px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #333333;
        margin-right: 190px;
      }
    }
  }

  .order_way {
    width: 1200px;
    margin: 20px auto 0;

    .way_title {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
      margin-left: 5px;
    }

    .way {
      width: 1200px;
      height: 96px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;

      .pay-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 176px;
        height: 56px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dbdbdb;
        font-size: 18px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #333333;
        margin-right: 27px;
      }

      .activepay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 176px;
        height: 56px;
        background: rgba(37, 134, 245, 0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #2586f5;
        font-size: 18px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #333333;
        margin-right: 27px;
      }

      .logo-img {
        width: 22px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .order {
    width: 1200px;
    margin: 20px auto 0;
    height: 90px;
    background: #ffffff;
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    .order_title {
      font-size: 16px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #777777;
      margin-right: 20px;

      .price {
        font-size: 30px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: bold;
        color: #f9431e;
        margin-left: 12px;
      }
    }

    .order_btn {
      width: 220px;
      height: 48px;
      background: linear-gradient(88deg, #ff6d1e 0%, #fe420e 100%);
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
