<template>
  <div>
    <!-- 支付宝支付界面 -->
    <el-dialog
      :visible.sync="ifwXpay"
      class="wxPay"
      width="990px"
      append-to-body
      @close="close"
    >
      <div class="pay">
        <div class="title">微信扫码支付</div>
        <div class="price">
          支付 <span class="num">{{ price }}</span> 元
        </div>
        <qriously :value="url" class="img" :size="220" />
        <img class="icon" src="@/assets/img/user/weixinPay.png" />
        <!-- 二维码过期 -->
        <!-- <el-button type="text" @click="clickCannel">二维码已失效，请刷新</el-button> -->
        <div class="tip">
          <span class="iconfont icon-a-lujing12"></span>
          <div>
            <div>打开手机微信</div>
            <div class="m-t-10">扫一扫继续付款</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="pay"
      width="368px"
      class="wxPay"
      append-to-body
      @close="closeCom"
    >
      <div class="pay-box">
        <i
          class="iconfont"
          :class="ifSucces ? 'icon-a-zu16' : 'icon-a-zu17'"
        ></i>
        <div class="tishi-title">{{ ifSucces ? "支付成功" : "支付失败" }}</div>
        <div class="tishi-tips">
          {{
            ifSucces ? "恭喜您，订单支付成功！" : "抱歉，支付失败，请重新支付。"
          }}
        </div>
        <el-button type="danger" round @click="closeCom">我知道了</el-button>
        <!-- <el-button v-else type="danger" round @click="closeCom">我知道了</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { CouponsClient } from "@/api/mine/couponsClient";
let couponsClient = new CouponsClient();
export default {
  components: {},
  props: [],
  props: ["url", "outTradeNo", "price"],
  watch: {
    url: function (newVal, oldVal) {
      this.getOrder();
    },
  },

  data() {
    return {
      ifwXpay: true,
      ifSucces: false, //是否支付成功
      pay: false, //显示支付完成提示
    };
  },
  created() {
    this.getOrder();
  },

  methods: {
    getOrder() {
      couponsClient.queryOrder(this.outTradeNo).then((res) => {
        if (res.code == 0) {
          // clearInterval(times);
          this.ifSucces = true;
        } else {
          this.ifSucces = false;
        }
        this.pay = true;
        this.$forceUpdate();
      });
    },
    clickCannel() {
      this.$emit("invalid");
    },
    close() {
      if (!this.pay) {
        this.closeCom();
      } else {
        ifwXpay = false;
      }
    },
    /* 关闭组件 */
    closeCom() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.pay {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 28px;
  color: #333333;

  margin-top: 29px;
  text-align: center;
}
.price {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #666666;

  margin-top: 56px;
  text-align: center;

  .num {
    color: #ff4027;
  }
}
.img {
  margin: 30px auto 0px;
  display: table;
  width: 220px;
  height: 220px;
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.icon {
  width: 194px;
  height: 178px;
  position: absolute;
  top: 183px;
  right: 152px;
}
.tip {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 20px;
  color: #666666;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 41px;
  /*  .iconBox {
    width: 50px;
    height: 50px;
   
  } */
  .iconfont {
    font-size: 50px;
    color: #ff7a3e;
    margin-right: 8px;
  }
}

/* 支付提示 */
.pay-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconfont {
    font-size: 80px;
  }
  .icon-a-zu16 {
    color: #5ab4fc;
  }
  .icon-a-zu17 {
    color: #fa6450;
  }
  .tishi-title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 30px;
    color: #333333;

    margin-top: 40px;
  }
  .tishi-tips {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 30px;
    color: #666666;

    margin-top: 24px;
  }
  /deep/ .el-button {
    width: 225px;
    margin-top: 56px;
    height: 46px;
    background: #ff4027;
  }
}
</style>
<style  lang="less">
.wxPay {
  .el-dialog__body {
    padding-bottom: 40px !important;
  }
}
</style>
